import Vue from "vue";
import PortalVue from "portal-vue";
import { getUserData } from "@/auth/utils";
Vue.use(PortalVue);

Vue.directive("canSee", {
  bind: function (el, binding, vnode) {
    if (binding != null) {
      var a = getUserData().ability.filter(
        (ab) => ab.subject == vnode.context.$router.currentRoute.meta.resource
      );
      a = a.filter((a) => a.action == binding.value);
      if (!a.length) {
        el.innerHTML =
          "<div>Bu işlem için yetkiniz yok lütfen yöneticinizle iletişime geçin.</div>";
        el.style.alignItems = "center";
        el.style.justifyContent = "center";
      }
    }
  },
});
